<template>
    <form style="width: 800px; max-width: 100%" @submit.prevent="save" v-if="personas.length">
      <h3 class="card-title">Cargar permiso de guardia</h3>
      <div class="cols2">
        <suggest
          id="empleado"
          label="Empleado"
          placeholder="Buscar empleado.."
          :value="empleado"
          :data="personas"
          :items="personaList"
          @selected="empleadoSelected"
          :required="enviado && !empleado"
        ></suggest>
  
        <div class="form-group">
          <label class="form-label">Puede crear/editar</label>
          <select
            id="publicado"
            class="form-control"
            v-model="permiso.can_create"
          >
            <option :value="1">Si</option>
            <option :value="0">No</option>
          </select>
        </div>
      </div>
      <button class="btn btn-primary mt-2" type="submit">Guardar</button>
    </form>
  </template>
  <script>
  import { PersonasService } from '../../personas/services/PersonasServices';
  import Suggest from '@/components/elements/Suggest';
  import PermisoGuardiaService from '../services/PermisoGuardiaService';
import Swal from 'sweetalert2';
  export default {
    name: 'EditPermiso',
    components: {
      Suggest,
    },
    props: {
        permisoToEdit: {
        required: true,
        type: Object,
        },
    },
    data() {
      return {
        enviado: false,
        permiso: {
          can_create: 0,
          persona_id: null,
        },
        empleado: null,
        personaList: PersonasService.dataList(),
        personas: [],
      };
    },
    async mounted() {
        this.permiso = Object.assign({}, this.permisoToEdit)
        this.personas = await PersonasService.api.getAllGuardias()
        this.empleado = this.personas.find(persona => persona.id === this.permiso.persona_id)
    },
    methods: {
      save() {
        this.enviado = true;
        if (!this.permiso.persona_id) {
          return;
        }
        PermisoGuardiaService.api
          .edit(this.permiso)
          .then(() => {
              this.$emit('saved');
              Swal.fire('Éxito', `<p>Se guardó con éxito.</p>`, 'success');
          })
          .catch((e) => {
            console.log(e)
            Swal.fire('Error', `<p>${e}</p>`, 'error');
          });
      },
      empleadoSelected(empleado) {
        if (empleado) {
          this.permiso.persona_id = empleado.id;
          this.empleado = empleado;
        }
      },
    },
  };
  </script>
  