<template>
  <div class="card" v-if="permisos">
    <h3 class="card-title">Listar permisos de guardias</h3>
    <div>
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="permisos"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="permisoClicked"
      ></table-list>
    </div>
    <Modal v-if="showAdd" @close="showAdd = false">
        <CreatePermiso
        slot="modal-body"
        @saved="saved"
        />
    </Modal>
    <Modal v-if="showEdit" @close="showEdit = false">
        <EditPermiso 
          :permisoToEdit="permisoSelected"
          slot="modal-body"
          @saved="edited"
        />
    </Modal>
  </div>
</template>
<script>
import PermisoGuardiaService from '../services/PermisoGuardiaService';
import TableList from '../../../components/elements/TableList.vue';
import Modal from '../../../components/elements/Modal.vue';
import CreatePermiso from './CreatePermiso.vue';
import EditPermiso from './EditPermiso.vue';
import Swal from 'sweetalert2';
export default {
  name: 'ListPermisoGuardia',
  components: {
    TableList,
    Modal,
    CreatePermiso,
    EditPermiso
},
  data() {
    return {
      permisos: null,
      showAdd: false,
      showEdit: false,
      permisoSelected: null
    };
  },
  computed: {
    headers() {
      return [
        { value: 'Empleado', sortable: true },
        { value: 'Gerencia', sortable: true },
        { value: 'Area', sortable: true },
        { value: 'Puede crear/editar', sortable: true },
      ];
    },
    body() {
      return [
        {
          class: 'important',
          data: [{ value: 'persona.nombre', class: 'text-primary' }],
        },
        {
          class: 'w-100',
          data: [{ value: 'persona.gerencia.descripcion', label: 'Gerencia' }],
        },
        {
          class: 'w-100',
          data: [{ value: 'persona.area.descripcion', label: 'Area' }],
        },
        {
          class: 'w-100',
          data: [
            {
              value: 'can_create',
              label: 'Puede crear/editar',
              mutator: (item) => (item == 1 ? 'SI' : 'NO'),
            },
          ],
        },
      ];
    },
    actionButton() {
      return {
        text: 'Nuevo permiso',
        action: () => {
          this.showAdd = true;
        },
      };
    },
  },
  async mounted() {
    await this.getPermisos();
  },
  methods: {
    async getPermisos(){  
        this.permisos = await PermisoGuardiaService.api.getAll();
    },
    saved(){
        console.log('si')
      this.showAdd = false
      this.getPermisos()
    },
    edited(){
        console.log('si')
      this.showEdit = false
      this.permisoSelected = null
      this.getPermisos()
    },
    permisoClicked(permiso) {
      Swal.fire({
        title: '¿Que acción desea realizar?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Editar',
        denyButtonText: `Eliminar`,
        cancelButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.permisoSelected = permiso
            this.showEdit = true
          } else if (result.isDenied) {
            this.deletePermiso(permiso)
          }
        })
    },
    deletePermiso(permiso){
      Swal.fire({
        title: '¿Seguro desea eliminar??',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, seguro',
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          PermisoGuardiaService.api.delete(permiso.id).then(() => {
              this.getPermisos()
          })                    
        }
      })
    }
  },
};
</script>
