import axios from "axios"

export default {
    api: {
        async getAll(){
            return axios.get('permiso-guardia')
            .then((response) => { return response.data })
            .catch((error) => { throw error })
        },
        async add(permisoGuardia) {
            return axios.post('permiso-guardia', permisoGuardia)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async edit(permisoGuardia) {
            return axios.put('permiso-guardia/'+permisoGuardia.id, permisoGuardia)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async delete(permisoGuardiaId) {
            return axios.delete('permiso-guardia/'+permisoGuardiaId)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        }
    }
}